<template>
  <loading-panel v-if="isLoading" />
  <div v-else>
    <div class="page-container md-layout-column">
      <div class="container-fluid px-0 view-home-maquillate">
        <nav-bar-normal
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :limit-categories="this.limitCategories"
          :show-navbar="this.showNavbar"
          :show-navigation="this.showNavigation"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-normal>
        <!-- </div> -->
        <!-- <div id="app"> -->
        <nav-bar-mobile
          :bg-color="this.bgColor"
          :get-profile="getProfile"
          :limit-categories="this.limitCategories"
          :show-navbar="this.showNavbar"
          :show-search-mobile="this.showSearchMobile"
        ></nav-bar-mobile>
      </div>

      <div>
        <router-view></router-view>
      </div>

      <div class="view-home__part7 footer-mobile pt-5">
        <footer-home :limit-categories="this.limitCategories"></footer-home>
      </div>
      <div class="view-home__part7 footer-desk pt-5">
        <footer-in-show-course
          :limit-categories="this.limitCategories"
        ></footer-in-show-course>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_COURSES_BY_CATEGORY } from "@/store/actions/course";
import { mapGetters } from "vuex";
import NavBarMobile from "./components/header/navBarMobile.vue";
import NavBarNormal from "./components/header/navBarNormal.vue";
import FooterHome from "./components/footer/FooterHome.vue";
import FooterInShowCourse from "./components/footer/FooterInShowCourse.vue";
import LoadingPanel from "../../components/LoadingPanel";
import { USER_STATUS_INACTIVE } from "../../../src/util/constants";

export default {
  components: {
    NavBarMobile,
    NavBarNormal,
    FooterHome,
    FooterInShowCourse,
    LoadingPanel,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      isLoading: true,
      limitCategories: null,
      getProfilee: null,
      showExplore: false,
      showByCategory: false,
      coursesCategory: null,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      showMenu: false,
      menuTransitionDuration: 250,
      pageClass: "login-page",
      inputSearch: false,
      showCategories: false,
      showNavigation: false,
      showSidepanel: false,
      showSearchMobile: false,
      bgColor: true,
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getCartCount"]),
  },
  mounted() {
    if (this.getProfile && !this.getProfile.emailVerifiedAt) {
      return this.$router.push("/confirmar-correo");
    } else if (this.getProfile && this.getProfile.status === USER_STATUS_INACTIVE) {
      return this.$router.push("/usuario-inactivo");
    } else {
      this.isLoading = false;
    }
    this.showExplore = true;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    callCoursesByCategory(category_id) {
      this.$store
        .dispatch(GET_COURSES_BY_CATEGORY, category_id)
        .then((resp) => {
          this.coursesCategory = resp.data;
          console.log(this.coursesCategory);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    categories() {
      this.showCategories = !this.showCategories;
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.navbar-mobile {
  &.navbar-Header {
    display: none;
    margin-top: -2px;
    height: 68px;
    width: 100vw;
    background: transparent;
    position: fixed;
    transform: translate3d(0, 0, 0);
    transition: 0.3s all ease-out;
    z-index: 7;

    a {
      font-family: inherit !important;
    }

    @media (max-width: 1000px) {
      display: block !important;
    }

    .view-home-maquillate__header {
      margin-top: 13px;
    }
  }
}

.navbar-Header {
  margin-top: -2px;
  height: 68px;
  width: 100vw;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  z-index: 10;

  a {
    font-family: inherit !important;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  .view-home-maquillate__header {
    margin-top: 13px;
  }
}

.bg-transparent-header {
  background: transparent;
}

.bg-black {
  background: #000;
}

.navbar-Header.hidden-navbar-Header {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.textCard h-auto {
  height: 14vh;
  overflow-y: auto;
}

.instructorCard {
  margin-top: 1rem;
}
</style>
